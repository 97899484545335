import React from "react";
import Navbar from "./components/NavBar/NavBar";
import ErrorOverlay from "./components/ErrorOverlay/ErrorOverlay";
import Homescreen from "./components/HomeScreen/HomeScreen";
import "./App.css"
import { Provider } from 'react-redux';
import store from './redux/ConfigureStore';

function App() {
    return (
        <Provider store={store} >
        <Navbar />
        <Homescreen />
        <ErrorOverlay />
        </Provider>
    );
}
  
export default App;