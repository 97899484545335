import { combineReducers } from "redux";
import restaurantList from "./restaurantListReducer";
import user from "./userReducer";
import selectedRestaurant from "./selectedRestaurantReducer";
import search from "./searchReducer";
import apiCallsInProgress from "./apiStatusReducer";
import error from "./errorReducer";

const rootReducer = combineReducers({
    restaurantList,
    user,
    selectedRestaurant,
    search,
    apiCallsInProgress,
    error
})

export default rootReducer;