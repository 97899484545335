import React, { Component } from "react";
import "./FormFlow.css";
import FormControl from "../FormControl/FormControl";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import RestaurantSelection from "../RestaurantSelection/RestaurantSelection";
import SelectedRestaurant from "../SelectedRestaurant/SelectedRestaurant";
import { isDesktop } from "../../utils/utils";

class FormFlow extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            currentFormIndex: 0,
            restaurantIndex: 0
      }
    }

    componentDidMount() {
        
    }

    backSubmit = () => {
        //Switch back to the last form if one exists and call back submit function else stay on the same form and call submit function 
        this.setState({ currentFormIndex: this.state.currentFormIndex - 1 }, this.props.formFlow.formList[this.state.currentFormIndex].backFunction)
    }

    handleSubmit = () => {
        //Switch to next form if one exists and call submit function else stay on the same form and call submit function 
        if((this.props.formFlow.formList.length - 1) > this.state.currentFormIndex){
            this.setState({ currentFormIndex: this.state.currentFormIndex + 1 }, this.props.formFlow.formList[this.state.currentFormIndex].submitFunction)
        } else {
            this.setState({ currentFormIndex: this.state.currentFormIndex }, this.props.formFlow.formList[this.state.currentFormIndex].submitFunction)
        }
    }

    render() {
        const formObject = this.props.formFlow.formList[this.state.currentFormIndex];

            if(formObject.formType == "restaurantList"){
                return(
                    <div className="restaurant-form-flow-container">
                        <div className="form-flow-body-container">
                            <RestaurantSelection form={formObject} submitFunction={this.handleSubmit}/>
                        </div>
                    </div>
                )
            } else if(formObject.formType == "selectedRestaurant") {
                return (
                    <div className="restaurant-form-flow-container">
                        <div className="form-flow-body-container">
                            <SelectedRestaurant form={formObject} backFunction={this.backSubmit}/>
                        </div>
                    </div>
                )
            } else {
                let ButtonStyles ={}
                if(isDesktop()){
                    ButtonStyles = {maxWidth: '20%', maxHeight: '50%', minWidth: '20%', minHeight: '50%', fontSize: '150%'}
                } else {
                    ButtonStyles = {maxWidth: '40%', maxHeight: '100%', minWidth: '40%', minHeight: '100%', fontSize: '300%'}
                }
                return (
                    <div className="form-flow-container">
                        <div className="form-flow-body-container">
                            {formObject.controlList.map((control, i) => (
                                    <FormControl key={i} control={control} />
                                    ))}
                        </div>
                        <div className="form-submit-button-container">
                            <Button variant="contained" style={ButtonStyles} onClick={this.handleSubmit}>{formObject.submitButtonLabel}</Button>
                        </div>
                    </div>
                )
            }
    }
}

FormFlow.propTypes = {
    formFlow: PropTypes.object.isRequired
}

export default FormFlow
