import * as actions from '../../redux/actions/actions';
import store from '../../redux/ConfigureStore';

export let SelectionFlowModel = {
    formflowName: "SCFilterFlow",
    formList: [
        {
            formType: "default",
            submitButtonLabel:"Search",
            submitFunction: () => {
                store.dispatch(actions.fetchRestaurantData(store.getState().user.userLocation, store.getState().search.searchRadius));
            },
            controlList: [
                {
                    controlType: "slider",
                    controlLabel: "Search Range In Miles: ",
                    defaultValue: 10,
                    valueDisplayPostfix:" Miles",
                    onChange: (value) => { 
                        store.dispatch(actions.setSearchRadius(value));
                    }
                }
            ]
        },
        {
            formType: "restaurantList",
            approveButtonLabel:"Yes",
            rejectButtonLabel:"No",
            approveFunction: (itemIndex) => {
                store.dispatch(actions.setRestaurantItemStatus(itemIndex, "selected"));
            },
            rejectFunction: (itemIndex) => {
                store.dispatch(actions.setRestaurantItemStatus(itemIndex, "not-selected"));
            },
            retryOnErrorFunction: () => {
                store.dispatch(actions.fetchRestaurantData(store.getState().user.userLocation, store.getState().search.searchRadius));
            },
            submitFunction: () => {
                //get the current restaurant list
                const restaurantList = store.getState().restaurantList;

                //init the selected index list
                let selectedListIndexes = [];

                //loop through the current restaurant list
                for(const currentRestaurantIndex in restaurantList) {
                    //if the curret restaurant is selected then add its index to the selected list
                    if(restaurantList[currentRestaurantIndex].status == "selected"){
                        selectedListIndexes.push(currentRestaurantIndex);
                    }
                }

                //randomly select a restaurant from the selected list
                const selectedRestaurantIndex = selectedListIndexes[Math.floor(Math.random() * selectedListIndexes.length)];

                //dispatch update to the selected restaurant
                if(selectedListIndexes.length > 0){
                    store.dispatch(actions.setSelectedRestaurant(restaurantList[selectedRestaurantIndex]));
                }
            },
            controlList: [
                {
                    controlType: "image"
                }
            ]
        },
        {
            formType: "selectedRestaurant",
            submitButtonLabel:"Search",
            submitFunction: () => {
                location.reload();

            },
            rechooseFunction: () => {
                //get the current restaurant list
                const restaurantList = store.getState().restaurantList;

                //init the selected index list
                let selectedListIndexes = [];

                //loop through the current restaurant list
                for(const currentRestaurantIndex in restaurantList) {
                    //if the curret restaurant is selected then add its index to the selected list
                    if(restaurantList[currentRestaurantIndex].status == "selected" && restaurantList[currentRestaurantIndex].id != store.getState().selectedRestaurant.id){
                        selectedListIndexes.push(currentRestaurantIndex);
                    }
                }
                //randomly select a restaurant from the selected list
                const selectedRestaurantIndex = selectedListIndexes[Math.floor(Math.random() * selectedListIndexes.length)];

                //dispatch update to the selected restaurant
                if(selectedListIndexes.length > 0){
                    store.dispatch(actions.setSelectedRestaurant(restaurantList[selectedRestaurantIndex]));
                }
            },
            backFunction: () => {
                //get the current restaurant list
                let restaurantList = store.getState().restaurantList;

                //loop through the current restaurant list
                for(const currentRestaurantIndex in restaurantList) {
                    //clear all restaurant status
                    store.dispatch(actions.setRestaurantItemStatus(currentRestaurantIndex, ""));
                }

                //clear selected restaurant
                store.dispatch(actions.setSelectedRestaurant({}));


            },
            controlList: [

            ]
        }
    ] 
}