import React, { Component } from "react";
import './SelectedRestaurant.css';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import YelpInfo from "../YelpInfo/YelpInfo";
import { isDesktop } from "../../utils/utils";
import { gtag } from "ga-gtag";

import { connect } from 'react-redux';
//import * as actions from '../../actions/actions';

class SelectedRestaurant extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            restaurantIndex: 0
      }
    }

    componentDidMount() {

        gtag('event', 'restaurant_selected', {
            selected_restaurant: this.props.selectedRestaurant.name
          });
    }

    approveSubmit = () => {
        this.props.form.submitFunction();
    }

    rejectSubmit = () => {
        this.props.backFunction();
    }

    rechooseSubmit = () => {
        this.props.form.rechooseFunction();
    }

    render() {
        let restaurantObject = {};
        if(Object.keys(this.props.selectedRestaurant).length !== 0) {
            restaurantObject = this.props.selectedRestaurant;

            let ButtonStyles = {};
            if(isDesktop()) {
                ButtonStyles = {maxWidth: '30%', maxHeight: '90%', minWidth: '30%', minHeight: '90%', fontSize: '200%'};
            } else {
                ButtonStyles = {maxWidth: '30%', maxHeight: '100%', minWidth: '30%', minHeight: '100%', fontSize: '300%'};
            }

            return(
                <div className="selected-restaurant-form-flow-container">
                    <div className="selected-restaurant-form-flow-label-container">
                    <h1 className="selected-restaurant-form-flow-label">Selection has been made:</h1>
                        <h1 className="selected-restaurant-form-flow-label">{restaurantObject.name}</h1>
                    </div>
                    <div className="selected-restaurant-info-body-container">
                        <img className="selected-restaurant-image" src={restaurantObject.image_url} />
                        <YelpInfo restaurant={this.props.selectedRestaurant}/>
                    </div>
                    <div className="selected-restaurant-submit-button-container">
                        <Button className="rejectButton" variant="contained" style={ButtonStyles} onClick={this.rejectSubmit}>{"Reselect"}</Button>
                        <Button className="reselectButton" variant="contained" style={ButtonStyles} onClick={this.rechooseSubmit}>{"Rechoose"}</Button>
                        <Button className="approveButton" variant="contained" style={ButtonStyles} onClick={this.approveSubmit}>{"Yes"}</Button>
                    </div>
                </div>
            )
        } else {
            let ButtonStyles = {};
            if(isDesktop()) {
                ButtonStyles = {maxWidth: '30%', maxHeight: '90%', minWidth: '30%', minHeight: '90%', fontSize: '200%'};
            } else {
                ButtonStyles = {maxWidth: '30%', maxHeight: '100%', minWidth: '30%', minHeight: '100%', fontSize: '300%'};
            }

            return(
                <div className="selected-restaurant-form-flow-container">
                    <div className="selected-restaurant-form-flow-label-container">
                    <h1 className="selected-restaurant-form-flow-label">No Restaurant has been selected</h1>
                        <h1 className="selected-restaurant-form-flow-label"></h1>
                    </div>
                    <div className="selected-restaurant-info-body-container">
                    </div>
                    <div className="selected-restaurant-submit-button-container">
                        <Button className="rejectButton" variant="contained" style={ButtonStyles} onClick={this.rejectSubmit}>{"Reselect"}</Button>
                    </div>
                </div>
            )
        }
    }
}

SelectedRestaurant.propTypes = {
    formFlow: PropTypes.object,
    selectedRestaurant: PropTypes.object,
    form: PropTypes.object,
    submitFunction: PropTypes.func,
    backFunction: PropTypes.func
}

export default connect((state) => {
    return {
        selectedRestaurant: state.selectedRestaurant
    }
  
  })(SelectedRestaurant);