import React, { Component } from "react";
import "./SelectionFlow.css";
import { SelectionFlowModel } from "./SelectionFlowModel";
import FormFlow from "../FormFlow/FormFlow";

class SelectionFlow extends Component {
    state = { clicked: false }
    
    componentDidMount() {

    }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        
        return (
            <div id="selection-flow-container">
                <FormFlow formFlow={SelectionFlowModel} />
            </div>
        )
    }
}

export default SelectionFlow