import React, { Component } from "react";
import HSMap from "../HSMap/HSMap";
import SelectionArea from "../SelectionArea/SelectionArea";
import { isDesktop } from "../../utils/utils";
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from '../../redux/actions/actions';

class Homescreen extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {

  }
}
    
componentDidMount(){

  let success = (position) => {
    //set user location with current location response
    let currentLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
    };

    //dispatch action to set the status 
    this.props.dispatch(actions.setUserLocation(currentLocation));

    //dispatch action to indicate that the current user location has been set
    this.props.dispatch({type:"FETCH_CURRENT_USER_LOCATION_SUCCESS"});

    }

    let error = () => {
        //dispatch action to indicate that pulling the current location as failed
        this.props.dispatch({type:"FETCH_CURRENT_USER_LOCATION_FAILURE", data: "Location services are not enabled. Please enable location services to continue."})
    }

    //call function to pull current location
    navigator.geolocation.getCurrentPosition(success, error);

}

    render() {
        if(isDesktop()){
          return (
            <>
            <div id="homescreen-container">
              <HSMap userLocation={this.state.userLocation} />
              <SelectionArea />
              </div>
            </>
          )
        } else {
          return (
            <>
            <div id="homescreen-container">
              <SelectionArea />
              </div>
            </>
        )
        }
    }
}

//export default Homescreen

Homescreen.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object
}

export default connect((state) => {

  return {
      user: state.user
  }

})(Homescreen);