var defaultState = {
    searchRadius: 25
};

export default function searchReducer(state = defaultState, action) {
    switch (action.type) {
        case ("SET_SEARCH_RADIUS"):
            return {
                ...state,
                searchRadius: action.data
            }
        default:
            return state;
    }
}