import React, { Component } from "react";
import './NavBar.css'
import decisiveeatslogo from '../../assets/decisiveeatslogo/DecisiveEatsLogo.png'

class Navbar extends Component {
    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        return (
            <nav className="navbar-container">
                <img src={decisiveeatslogo} alt="Decisive Eats" className="logo" />
            </nav>
        )
    }
}

export default Navbar