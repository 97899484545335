import React, { Component } from "react";
import "./YelpInfo.css"
import PropTypes from 'prop-types';

//import Yelp Images
import YelpLogoImage from '../../assets/yelplogo/yelp_logo_dark_bg.png';
import YelpFive from '../../assets/yelpstars/regular_5.png';
import YelpFourHalf from '../../assets/yelpstars/regular_4_half.png';
import YelpFour from '../../assets/yelpstars/regular_4.png';
import YelpThreeHalf from '../../assets/yelpstars/regular_3_half.png';
import YelpThree from '../../assets/yelpstars/regular_3.png';
import YelpTwoHalf from '../../assets/yelpstars/regular_2_half.png';
import YelpTwo from '../../assets/yelpstars/regular_2.png';
import YelpOneHalf from '../../assets/yelpstars/regular_1_half.png';
import YelpOne from '../../assets/yelpstars/regular_1.png';
import YelpZero from '../../assets/yelpstars/regular_0.png';


class YelpInfo extends Component {
    state = {  }

    handleLogoClick = () => {
        window.open(this.props.restaurant.url, "_blank");
    }

    getYelpStarsImg = () => {
        switch (this.props.restaurant.rating) {
            case 5:
              return YelpFive
            case 4.5:
                return YelpFourHalf
            case 4:
                return YelpFour
            case 3.5:
                return YelpThreeHalf
            case 3:
                return YelpThree
            case 2.5:
                return YelpTwoHalf
            case 2:
                return YelpTwo
            case 1.5:
                return YelpOneHalf
            case 1:
                return YelpOne
            default:
              return YelpZero
          }
    }

    getYelpLogoImg = () => {
        return YelpLogoImage
    }

    getCombinedCategories = () => {
        let combinedCategories = this.props.restaurant.categories.map(item => {return item.title})
        return combinedCategories.join(", ")
    }


    render() {
        console.log("Combined Categories", this.getCombinedCategories());
        return (
            <div className="yelp-info-container">
                <div className="yelp-info-upper-container">
                    <div className="yelp-logo-container">
                        <img className="yelp-logo-img" src={this.getYelpLogoImg()} onClick={this.handleLogoClick}></img>
                    </div>
                    <div className="yelp-stars-container">
                        <img className="yelp-stars-img" src={this.getYelpStarsImg()}></img>
                    </div>
                </div>
                <div className="yelp-catergories-container">
                    {this.getCombinedCategories()}
                </div>
                <div className="yelp-after-stars-info-container">
                    {this.props.restaurant.review_count + " reviews "}
                    &nbsp; &#183; &nbsp;
                    {!this.props.restaurant.price ? '$ Not Listed' : this.props.restaurant.price}
                </div>
            </div>
        )
    }
}

YelpInfo.propTypes = {
    restaurant: PropTypes.object
}

export default YelpInfo