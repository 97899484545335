import React, { Component } from "react";
import "./SelectionArea.css"
import SelectionFlow from "../SelectionFlow/SelectionFlow";

class SelectionArea extends Component {
    render() {
        return (
            <div className="selection-area-container">
                <SelectionFlow />
            </div>
        )
    }
}

export default SelectionArea