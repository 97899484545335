import React, { Component } from "react";
import './RestaurantSelection.css';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import PacmanLoader from "react-spinners/PacmanLoader";
import YelpInfo from "../YelpInfo/YelpInfo";
import { isDesktop } from "../../utils/utils";
import { Icon } from '@iconify/react'

import { connect } from 'react-redux';
//import * as actions from '../../actions/actions';

class RestaurantSelection extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            restaurantIndex: 0
      }
    }

    componentDidMount() {
        
    }

    approveSubmit = () => {
        //Switch to restaurant form if one exists else submit restaurant selection
        if((this.props.restaurantList.length - 1) > this.state.restaurantIndex){
            this.setState({ restaurantIndex: this.state.restaurantIndex + 1 }, this.props.form.approveFunction(this.state.restaurantIndex))
        } else {
            this.setState({ restaurantIndex: this.state.restaurantIndex }, this.props.submitFunction)
        }
    }

    selectSubmit = () => {
        //Jump to selection
        this.setState({ restaurantIndex: this.state.restaurantIndex }, this.props.submitFunction)
    }

    rejectSubmit = () => {
        //Switch to restaurant form if one exists else submit restaurant selection
        if((this.props.restaurantList.length - 1) > this.state.restaurantIndex){
            this.setState({ restaurantIndex: this.state.restaurantIndex + 1 }, this.props.form.rejectFunction(this.state.restaurantIndex))
        } else {
            this.setState({ restaurantIndex: this.state.restaurantIndex }, this.props.submitFunction)
        }

    }

    retryOnError = () => {
        //retry api call on failure
        this.setState({ restaurantIndex: this.state.restaurantIndex }, this.props.form.retryOnErrorFunction)

    }

    render() {
        let restaurantObject = {};
        if(this.props.restaurantList.length > 0) {
            restaurantObject = this.props.restaurantList[this.state.restaurantIndex];
            let ButtonStyles = {};

            if(isDesktop()) {
                ButtonStyles = {maxWidth: '30%', maxHeight: '90%', minWidth: '30%', minHeight: '90%', fontSize: '200%'};
            } else {
                ButtonStyles = {maxWidth: '45%', maxHeight: '100%', minWidth: '45%', minHeight: '100%', fontSize: '300%'};
            }

            return(
                <div className="restaurant-form-flow-container">
                    <div className="restaurant-form-flow-label-container">
                        <Icon className="selectButton" icon="ant-design:right-outlined" onClick={() => {this.selectSubmit();}} />
                        <h1 className="restaurant-form-flow-label">{restaurantObject.name}</h1>
                    </div>
                    <div className="restaurant-info-body-container">
                        <div className="restaurant-image-container">
                            <img className="restaurant-image" src={restaurantObject.image_url} />
                        </div>
                        <YelpInfo restaurant={restaurantObject}/>
                    </div>
                    <div className="restaurant-submit-button-container">
                        <Button className="rejectButton" variant="contained" style={ButtonStyles} onClick={this.rejectSubmit}>{"No"}</Button>
                        <Button className="approveButton" variant="contained" style={ButtonStyles} onClick={this.approveSubmit}>{"Yes"}</Button>
                    </div>
                </div>
            )
        } else if (this.props.apiCallsInProgress > 0){
            let LoaderStyle = {};
            let LoaderSize = 20;

            if(isDesktop()) {
                LoaderStyle = { position: "relative", top: "40vh", left: "30%" };
                LoaderSize = 30;
            } else {
                LoaderStyle = { position: "relative", top: "40vh", left: "30%" };
                LoaderSize = 45;
            }

            return(
                <div className="loading-indicator-container">
                <PacmanLoader color={"#ffffff"} loading={true} css={LoaderStyle} size={LoaderSize} />
                </div>
            )
        } else if (this.props.error.hasError) {
            let ButtonStyles = {};

            if(isDesktop()) {
                ButtonStyles = {maxWidth: '30%', maxHeight: '5%', minWidth: '30%', minHeight: '5%', fontSize: '100%'};
            } else {
                ButtonStyles = {maxWidth: '45%', maxHeight: '7%', minWidth: '45%', minHeight: '7%', fontSize: '100%'};
            }
            
            return(
                <div className="restaurant-form-flow-container">
                    <div className="restaurant-form-flow-label-container">
                        {"Whoops.. looks like we have an error.  " + this.props.error.errorMessage}
                    </div>
                    <div className="restaurant-info-body-container">
                        <Button className="retryButton" variant="contained" style={ButtonStyles} onClick={this.retryOnError}>{"Try Again.."}</Button>
                    </div>
                    <div className="restaurant-submit-button-container">
                    </div>
                </div>
                
                )
        } else {
            return(<></>)
        }
    }
}

RestaurantSelection.propTypes = {
    formFlow: PropTypes.object,
    restaurantList: PropTypes.array,
    form: PropTypes.object,
    submitFunction: PropTypes.func,
    apiCallsInProgress: PropTypes.number,
    error: PropTypes.object

}

export default connect((state) => {
    return {
        restaurantList: state.restaurantList,
        apiCallsInProgress: state.apiCallsInProgress,
        error: state.error
    }
  
  })(RestaurantSelection);