//Define action creators for the main redux store

//make the needed imports
import axios from 'axios';
import { Services } from '../../services/services';
import store from '../ConfigureStore';


//SYNCHRONUS ACTIONS
//set user location
export function setUserLocation(userLocation) {
    
    return {
        type: "SET_USER_LOCATION_BEGIN",
        data: userLocation
    }
}

//set search radius
export function setSearchRadius(searchRadius) {
    
    return {
        type: "SET_SEARCH_RADIUS",
        data: searchRadius
    }
}

//set selected restaurant
export function setSelectedRestaurant(selectedRestaurant) {
    
    return {
        type: "SET_SELECTED_RESTAURANT",
        data: selectedRestaurant
    }
}

//update restaurant item status
export function setRestaurantItemStatus(restaurantItemIndex, restaurantStatus) {

    //Create restaurant Item Copy
    let restaurantCopy = Object.assign({},store.getState().restaurantList[restaurantItemIndex]);

    //Update restaurant copy status
    restaurantCopy.status = restaurantStatus;

    return {
        type: "SET_RESTAURANT_DATA_BY_ID",
        id: restaurantItemIndex,
        data: restaurantCopy
    }
}

//ASYNC FUNCTIONS

//fetch restaurant data supporting actions

export function fetchRestaurantData(userLocation,searchRadius) {
    return (dispatch) =>  {
    dispatch({type:"FETCH_RESTAURANT_LIST_DATA_BEGIN"});

    let searchRadiusInMeters = searchRadius * 1609.34;

    axios.get(Services.resurantsEndpoint + "?type=resturant&latitude=" + userLocation.latitude + "&longitude=" + userLocation.longitude + "&radius=" + searchRadiusInMeters, Services.restaurantConfig)
        .then(resp => {
            //get only businesses data from response object
            return resp["data"]["businesses"]
        })
        .then(data => {
            //set businesses data into redux store
            dispatch({type:"SET_RESTAURANT_LIST_DATA", data: data});
            dispatch({type:"SET_RESTAURANT_LIST_DATA_SUCCESS"});
        })
        .catch(() => {
            dispatch({type:"RECIEVED_RESTAURANT_LIST_DATA_FAILURE", data: "Whoops, we cannot retrieve your restaurant data. Please try again.."})
        } );
    }
}
