var defaultState = {
    userLocation: {
        latitude: "",
        longitude: ""
    },
    locationSet: false
};

export default function userReducer(state = defaultState, action) {
    switch (action.type) {
        case ("SET_USER_LOCATION_BEGIN"):
            return {
                ...state,
                userLocation: action.data
            }
        case ("FETCH_CURRENT_USER_LOCATION_SUCCESS"):
            return {
                ...state,
                locationSet: true
            }
        default:
            return state;
    }
}