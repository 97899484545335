import update from 'react-addons-update';

export default function restaurantListReducer(state = [], action) {
    switch (action.type) {
        case ("SET_RESTAURANT_LIST_DATA"):
                return [...action.data]
        case 'SET_RESTAURANT_DATA_BY_ID':
                return update(state, { 
                    [action.id]: {$set: action.data}
                });
        default:
            return state;
    }
}