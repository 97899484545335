import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react';
import { HSMapStyle } from "./HSMapStyle";
import { Icon } from '@iconify/react'
import PropTypes from 'prop-types';
import "./HSMap.css"

import { connect } from 'react-redux';
//import * as actions from '../../actions/actions';

class HSMap extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
       isLoading: true
  }

}

componentDidMount(){

}

onMapChange = () => {}


render() {
  let isLoading = !(!this.props.userLocation.latitude && !this.props.userLocation.longitude)
  let mapProps = {
    center: {
      lat: this.props.userLocation.latitude,
      lng: this.props.userLocation.longitude
    },
    zoom: 13
  }

  const defaultMapProps = {
    mapId: "d92f64a75d648f98",
    mapOptions: {
        styles: HSMapStyle
    }
  };

  if(isLoading == false) {
    return(
      <></>
    )
  } else {
    return (
      <div style={{ height: '93vh', width: '100%', flex: '0 0 100%' }}>
        <GoogleMapReact
          key={"HSMap"}
          bootstrapURLKeys={{ key: "AIzaSyC6EPKbgdcq37X5rPZVT3tNAmKIDp6xk4c" }}
          defaultCenter={{lat: this.props.userLocation.latitude, lng: this.props.userLocation.longitude}}
          defaultZoom={mapProps.zoom}
          MapTypeStyle={HSMapStyle}
          options={defaultMapProps.mapOptions}
          onChange={this.onMapChange}
          restautantList={this.props.restaurantList}
        >
          <Icon lat={this.props.userLocation.latitude} lng={this.props.userLocation.longitude} className="user-icon" icon="gis:position-man" />
          {this.props.restaurantList.map((restaurant) => (
                            <Icon key={restaurant.id} lat={restaurant.coordinates.latitude} lng={restaurant.coordinates.longitude} className="restaurant-icon" icon="gis:pin" />
            ))}

        </GoogleMapReact>
      </div>
      )
    }

  }

}

HSMap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userLocation: PropTypes.object,
  restaurantList: PropTypes.array
}

export default connect((state) => {
  return {
      userLocation: {latitude: state.user.userLocation.latitude,
                     longitude: state.user.userLocation.longitude },
      restaurantList: state.restaurantList
  }

})(HSMap);
